import React, { Component } from 'react';
import AsyncSelect from "react-select/async";
import axios from "axios";
import PropTypes from "prop-types";

const badgeClass = (status) => {
  switch (status) {
    case '登録待ち':
      return 'badge badge-secondary'
    case '完了':
      return 'badge badge-success'
    case 'キャンセル':
      return 'badge badge-danger'
  }
}

export default class OrderInfo extends Component {
  constructor(props) {
    super(props)

    let shopVendor = this.props.defaultShopVendor ? { 'value': this.props.defaultShopVendor.id, 'label': this.props.defaultShopVendor.name } : null

    this.state = {
      selectedShopVendor: shopVendor,
      isLoading: false
    }

    this.editorStyle = this.props.editorStyle
  }

  handleChange = selectedShopVendor => {
    let shouldChange = false;
    if (this.state.selectedShopVendor == null) {
      shouldChange = true
    } else if (this.state.selectedShopVendor.value != selectedShopVendor.value) {
      shouldChange = confirm("受注者を変更すると納品明細の内容がリセットされますがよろしいですか？");
    }

    if (shouldChange) {
      this.setState(
          { selectedShopVendor },
          () => {
            this.props.onShopVendorSelected({ 'id': this.state.selectedShopVendor.value, 'name': this.state.selectedShopVendor.label })
          }
      );
    }
  };

  promiseOptions = inputValue => {
    const token = document.getElementsByName('csrf-token').item(0).content
    const url = Routes.commons_api_delivery_note_photos_shop_shop_vendors_path({shop_id: this.props.deliveryNotePhoto.shop.id, query: inputValue})
    let params = new FormData()
    params.append("_method", "get")
    params.append("authenticity_token", token)

    return new Promise(function(resolve, reject) {
      axios.get(url, params)
          .then(response => {
            resolve(response.data["shop_vendors"].map(({ id, name }) => ({ value: id, label: name })))
          })
          .catch((e) => {
            console.log(e);
            alert('受注者の検索に失敗しました。')
          })
    })
  }

  handleAiProcess = (e) => {  
    const selectedShopVendor = { 'value': this.props.deliveryNotePhoto.ai_output.id, 'label': this.props.deliveryNotePhoto.ai_output.name }
      this.setState(
        { selectedShopVendor },
        () => {
          this.props.onShopVendorSelected(this.props.deliveryNotePhoto.ai_output)
        }
    );
  }

  render () {
    const { selectedShopVendor } = this.state
    const { deliveryNotePhoto, isEditable, editorStyle, latestDeliveryNotePhotoEditLog } = this.props;
    console.log(latestDeliveryNotePhotoEditLog);
    return (
      <>
        <div className={`${this.editorStyle.box()}`}>
          <div className={this.editorStyle.boxHeader()}>
            <h3 className={this.editorStyle.title()}>納品書情報</h3>
          </div>
          <div className={`${this.editorStyle.boxDetail()} ${this.editorStyle.isCs() == true ? this.editorStyle.marginTop() : ''}`}>
            <div>
              <label className={this.editorStyle.label()}>発注者</label>
              <div>
                {
                  isEditable ? (
                    editorStyle.isCs() == true ? (
                      <a target="blank" href={`/cs/shops/${deliveryNotePhoto.shop.id}`}>{deliveryNotePhoto.shop.name}</a>
                    ) : (
                      deliveryNotePhoto.shop.name 
                    )
                  ) : deliveryNotePhoto.shop.name                                
                }                
              </div>
            </div>
            <div className={`${this.editorStyle.marginTop()}`}>
              <div>
                <label className={this.editorStyle.label()}>受注者</label>
              </div>
              {isEditable && this.state.selectedShopVendor
                ? <div className={this.editorStyle.textRight()}>
                    <label className={this.editorStyle.label()}>                
                      <a target="blank" href={editorStyle.isCs() ? `/cs/shop_vendors/${this.state.selectedShopVendor.value}/unofficial_items` : `/shop_vendors/${this.state.selectedShopVendor.value}/items`}>
                        <small>商品の一覧</small>
                      </a>
                    </label>
                  </div>
                : ''
              }
            </div>

            {deliveryNotePhoto.shopVendor
                ? (<div>{deliveryNotePhoto.shopVendor.name}</div>)
                : (
                  isEditable ? (
                    <AsyncSelect
                    value={selectedShopVendor}
                    onChange={this.handleChange}
                    defaultOptions
                    loadOptions={this.promiseOptions}
                    placeholder="受注者を選択"
                  />
                  ) : (
                    selectedShopVendor ? selectedShopVendor.label : '登録待ち'                    
                  )
                )                
            }

            { this.editorStyle.isCs() ?             

            this.props.deliveryNotePhoto.ai_output != null && this.props.deliveryNotePhoto.ai_output.id != null ?
              <>
                <div className="has-text-right" style={{ marginTop: '10px', marginBottom: '10px'}}>
                  <button type="button" className="button is-warning is-small" onClick={ () => this.handleAiProcess() }>
                    <span className="icon-text">
                      <span className="icon">
                        <i class="fas fa-magic"></i>
                      </span>
                      <span>AI推測結果「{this.props.deliveryNotePhoto.ai_output.name}」を適用</span>
                    </span>            
                  </button>
                </div>
              </>
              : ''
            : '' }

            <div className={this.editorStyle.marginTop()}>
              <label className={this.editorStyle.label()}>処理ステータス</label>
              <div>
                <span class={badgeClass(deliveryNotePhoto.status)}>{deliveryNotePhoto.status}</span>                
                { deliveryNotePhoto.cancelReason && <span>（{deliveryNotePhoto.cancelReason}）</span> }
                { latestDeliveryNotePhotoEditLog && <div><span><small>（最終編集: {latestDeliveryNotePhotoEditLog.editor_type == 'admin_user' ? 'ラクミー' : 'お客様'}）</small></span></div>}
              </div>              
            </div>
            <div className={this.editorStyle.marginTop()}>
              <label className={this.editorStyle.label()}>納品日</label>
              <div>
                { isEditable ? <input className={this.editorStyle.input()} name="closed_date" required={true} type="date" defaultValue={deliveryNotePhoto.closedDate}/> : deliveryNotePhoto.closedDate}
              </div>       
            </div>            
          </div>
        </div>
      </>
    )
  }
}

OrderInfo.propTypes = {
  deliveryNotePhoto: PropTypes.object.isRequired,
  defaultShopVendor: PropTypes.object,
  onShopVendorSelected: PropTypes.func.isRequired
}
